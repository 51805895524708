define("cms-frontend/components/wrapper-components/carousel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BIAJxP9c",
    "block": "{\"symbols\":[\"header\",\"items\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"transform-contentful\",[[23,3,[\"header\"]]],null],[28,\"transform-contentful-media\",[[28,\"transform-contentful\",[[23,3,[\"items\"]]],[[\"list\"],[true]]]],[[\"list\",\"prop\"],[true,\"imgSrc\"]]]],null,{\"statements\":[[0,\"  \"],[5,\"carousel\",[],[[\"@header\",\"@items\"],[[28,\"hash\",null,[[\"title\",\"description\"],[[23,1,[\"title\"]],[23,1,[\"description\"]]]]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/carousel/template.hbs"
    }
  });

  _exports.default = _default;
});