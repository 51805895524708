define("cms-frontend/translations/de-de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "404": {
      "cta": "zur Startseite",
      "title": "Sorry! Wir konnten die gesuchte Seite nicht finden."
    },
    "breadcrumbs": {
      "accessibility_label": "Breadcrumbs"
    },
    "button": {
      "spinner_accessibility_label": "Wird geladen"
    },
    "carousel": {
      "navigation": {
        "next": "Nächste Ansicht",
        "previous": "Vorherige Ansicht"
      }
    },
    "common": {
      "new_window_accessibility_label": "(öffnet ein neues Fenster)"
    },
    "footer": {
      "authorization": {
        "approval": "durch die IHK",
        "title": "Zugelassen"
      },
      "companies": {
        "about_us": "Über uns",
        "career": "Karriere",
        "press": "Presse",
        "title": "Unternehmen"
      },
      "contact": {
        "email": "kundenservice@clark.de",
        "phone": "069 153 229 339",
        "timings": "(Mo. - Fr. 08 - 19 Uhr)",
        "title": "Kontakt"
      },
      "copyright": {
        "conditions": "AGB",
        "data_protection": "Datenschutz",
        "imprint": "Impressum",
        "initial_information": "Erstinformation",
        "title": "&#169; Clark Germany GMBH 2019"
      },
      "datacenter": {
        "location": "in Deutschland",
        "title": "Rechenzentrum"
      },
      "information": {
        "function": "So funktioniert’s",
        "magazine": "CLARK Magazine",
        "partner": "Partner",
        "title": "Informationen"
      }
    },
    "forms": {
      "email": {
        "error_message": "Ungültige E-Mailadresse"
      }
    },
    "header": {
      "cta": {
        "login": "Einloggen",
        "mobile_install": "Installieren",
        "my_account": "Mein Konto",
        "start": "Jetzt starten"
      },
      "links": {
        "how": "So funktioniert’s",
        "insurances": {
          "subMenu": {
            "health": {
              "links": {
                "disability": "Berufsunfähigkeitsversicherung",
                "private": "Private Krankenversicherung"
              },
              "title": "Gesundheit & Eigentum"
            },
            "pension": {
              "links": {
                "company_scheme": "Betriebliche Altersvorsorge",
                "private_scheme": "Private Altersvorsorge",
                "risky_scheme": "Risikolebensversicherung"
              },
              "title": "Altersvorsorge"
            },
            "property": {
              "links": {
                "car": "Kfz-Versicherung",
                "house": "Hausratversicherung",
                "law": "Rechtsschutzversicherung",
                "liability": "Privathaftpflicht­versicherung"
              },
              "title": "Besitz\t& Eigentum"
            }
          },
          "title": "Versicherungen"
        }
      }
    },
    "lead_gen": {
      "terms_and_condition": "Ich bin damit einverstanden, dass CLARK Germany mich per E-mail und zur Klärung von Rückfragen telefonisch kontaktiert. Es gelten unsere Bestimmungen für den Datenschutz."
    },
    "name": "Clark",
    "skepticsSlider": {
      "next": "Weiter",
      "play": "Video zu {name} anzeigen"
    }
  };
  _exports.default = _default;
});