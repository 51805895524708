define("cms-frontend/config/seo-routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    prefix: 'altersvorsorge'
  }, {
    prefix: 'auslandskrankenversicherung'
  }, {
    prefix: 'berufsunfaehigkeitsversicherung'
  }, {
    prefix: 'betriebliche-altersvorsorge'
  }, {
    prefix: 'gesetzliche-krankenversicherung'
  }, {
    prefix: 'haftpflichtversicherung'
  }, {
    prefix: 'hausratversicherung'
  }, {
    prefix: 'kfz-versicherung'
  }, {
    prefix: 'krankenhauszusatzversicherung'
  }, {
    prefix: 'krankenzusatzversicherung'
  }, {
    prefix: 'pflegeversicherung'
  }, {
    prefix: 'pflegezusatzversicherung'
  }, {
    prefix: 'private-altervorsorge'
  }, {
    prefix: 'private-krankenversicherung'
  }, {
    prefix: 'private-rentenversicherung'
  }, {
    prefix: 'rechtsschutzversicherung'
  }, {
    prefix: 'riester-rente'
  }, {
    prefix: 'risikolebensversicherung'
  }, {
    prefix: 'ruerup-rente'
  }, {
    prefix: 'sterbegeldversicherung'
  }, {
    prefix: 'zahnzusatzversicherung'
  }];
  _exports.default = _default;
});