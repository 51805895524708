define("cms-frontend/components/wrapper-components/trustpilot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GfjvGKvG",
    "block": "{\"symbols\":[\"model\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"header\",\"tag\"],[[28,\"transform-contentful\",[[23,2,[\"header\"]]],null],[23,2,[\"tag\"]]]]]],null,{\"statements\":[[0,\"  \"],[5,\"trustpilot\",[],[[\"@header\",\"@tag\"],[[23,1,[\"header\"]],[23,1,[\"tag\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/trustpilot/template.hbs"
    }
  });

  _exports.default = _default;
});