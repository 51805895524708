define("cms-frontend/components/wrapper-components/highlight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r1dIGEpP",
    "block": "{\"symbols\":[\"cta\",\"@module\"],\"statements\":[[0,\"\\n\"],[5,\"highlight\",[],[[\"@title\",\"@description\",\"@cta\"],[[23,2,[\"title\"]],[23,2,[\"description\"]],[23,2,[\"cta\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,2,[\"cta\"]]],null,{\"statements\":[[4,\"let\",[[28,\"transform-contentful\",[[23,2,[\"cta\"]]],null]],null,{\"statements\":[[0,\"      \"],[5,\"button\",[[12,\"class\",[29,[[28,\"local-class\",[\"highlight-cta\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/highlight/styles\"]]]]]]],[[\"@isSecondary\",\"@url\"],[true,[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,1,[\"mobileLink\"]],[23,1,[\"desktopLink\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,1,[\"mobileText\"]],[23,1,[\"desktopText\"]]],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/highlight/template.hbs"
    }
  });

  _exports.default = _default;
});