define("cms-frontend/helpers/transform-contentful-rich-text", ["exports", "@contentful/rich-text-html-renderer", "@contentful/rich-text-types", "cms-frontend/components/wrapper-components/rich-text/styles", "cms-frontend/utils/rich-text-header"], function (_exports, _richTextHtmlRenderer, _richTextTypes, _styles, _richTextHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function transformContentfulRichText(params) {
    var _renderNode;

    var _params = _slicedToArray(params, 1),
        node = _params[0];

    var options = {
      renderNode: (_renderNode = {}, _defineProperty(_renderNode, _richTextTypes.BLOCKS.HEADING_2, function (headerNode, next) {
        return "<h2 id='".concat((0, _richTextHeader.default)(headerNode), "' class=\"").concat(_styles.default['rich-text-header-anchor'], "\" >").concat(next(headerNode.content), "</h2>");
      }), _defineProperty(_renderNode, _richTextTypes.BLOCKS.UL_LIST, function (unorderedListNode, next) {
        return "<ul class=".concat(_styles.default['rich-text-unordered-list'], ">").concat(next(unorderedListNode.content), "</ul>");
      }), _defineProperty(_renderNode, _richTextTypes.BLOCKS.PARAGRAPH, function (paragraphNode, next) {
        var style = paragraphNode.content[0].value === '' ? "class=".concat(_styles.default['empty-paragraph']) : '';
        return "<p ".concat(style, ">").concat(next(paragraphNode.content), "</p>");
      }), _defineProperty(_renderNode, _richTextTypes.BLOCKS.HR, function () {
        return "<hr class='".concat(_styles.default['line-breaker'], "'/>");
      }), _renderNode)
    };

    if (node.nodeType !== 'document') {
      return (0, _richTextHtmlRenderer.documentToHtmlString)({
        data: {},
        content: [node],
        nodeType: _richTextTypes.BLOCKS.DOCUMENT
      }, options);
    }

    return Ember.String.htmlSafe((0, _richTextHtmlRenderer.documentToHtmlString)(node, options));
  }

  var _default = Ember.Helper.helper(transformContentfulRichText);

  _exports.default = _default;
});