define("cms-frontend/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "breadcrumbs": {
      "accessibility_label": "Breadcrumbs"
    },
    "button": {
      "spinner_accessibility_label": "Loading"
    },
    "carousel": {
      "navigation": {
        "next": "Next slide",
        "previous": "Previous slide"
      }
    },
    "common": {
      "new_window_accessibility_label": "(opens a new window)"
    },
    "skepticsSlider": {
      "next": "Next",
      "play": "Open video for {name}"
    }
  };
  _exports.default = _default;
});