define('cms-frontend/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('cms-frontend');
} else {

          var exports = {
            'default': {"modulePrefix":"cms-frontend","environment":"production","rootURL":"/","routerRootURL":"","locationType":"router-scroll","historySupportMiddleware":true,"EmberENV":{"FEATURES":{"EMBER_NATIVE_DECORATOR_SUPPORT":true,"EMBER_METAL_TRACKED_PROPERTIES":true,"EMBER_GLIMMER_ANGLE_BRACKET_NESTED_LOOKUP":true,"EMBER_GLIMMER_ANGLE_BRACKET_BUILT_INS":true,"EMBER_GLIMMER_FN_HELPER":true,"EMBER_GLIMMER_ON_MODIFIER":true},"EXTEND_PROTOTYPES":{"Date":false},"_APPLICATION_TEMPLATE_WRAPPER":false,"_JQUERY_INTEGRATION":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true},"api":{"url":"/api/"},"contentful":{"accessToken":"zvos6nnGGT7BWnFzTtkzGnyMlBrjm8C4Ii-8edmr2t0","space":"zqe91pmti9ii","environment":"master","host":"cdn.contentful.com"},"fastboot":{"hostWhitelist":[{}]},"APP":{"name":"cms-frontend","version":"0.0.0+939462a3"},"freestyle":{"snippetSearchPaths":["addon","tests/dummy/app"]},"exportApplicationGlobal":false,"ember-modal-dialog":{}}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
