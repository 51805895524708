define("cms-frontend/components/wrapper-components/three-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z48SaMQZ",
    "block": "{\"symbols\":[\"model\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"hash\",null,[[\"header\",\"items\",\"footer\"],[[28,\"transform-contentful\",[[23,2,[\"header\"]]],null],[28,\"transform-contentful-media\",[[28,\"transform-contentful\",[[23,2,[\"items\"]]],[[\"list\"],[true]]]],[[\"list\",\"prop\"],[true,\"imgSrc\"]]],[28,\"if\",[[23,2,[\"footerBtn\"]],[28,\"transform-contentful\",[[23,2,[\"footerBtn\"]]],null],null],null]]]]],null,{\"statements\":[[0,\"  \"],[5,\"thumbnail-list\",[],[[\"@header\",\"@items\",\"@footer\"],[[23,1,[\"header\"]],[23,1,[\"items\"]],[28,\"hash\",null,[[\"isSecondary\",\"mobile\",\"desktop\"],[[23,1,[\"footer\",\"isSecondary\"]],[28,\"hash\",null,[[\"text\",\"href\"],[[23,1,[\"footer\",\"mobileText\"]],[23,1,[\"footer\",\"mobileLink\"]]]]],[28,\"hash\",null,[[\"text\",\"href\"],[[23,1,[\"footer\",\"desktopText\"]],[23,1,[\"footer\",\"desktopLink\"]]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/three-column/template.hbs"
    }
  });

  _exports.default = _default;
});