define("cms-frontend/components/wrapper-components/centered-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IvV6rseY",
    "block": "{\"symbols\":[\"header\",\"imgSrc\",\"footer\",\"@module\"],\"statements\":[[4,\"let\",[[28,\"transform-contentful\",[[23,4,[\"header\"]]],null],[28,\"transform-contentful-media\",[[23,4,[\"imgSrc\"]]],null],[28,\"transform-contentful\",[[23,4,[\"footerButton\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"centered-content\",[],[[\"@header\",\"@body\",\"@footer\"],[[28,\"hash\",null,[[\"title\"],[[23,1,[\"title\"]]]]],[28,\"hash\",null,[[\"leftContent\",\"imgSrc\",\"rightContent\"],[[28,\"hash\",null,[[\"title\",\"description\"],[[23,4,[\"leftContentTitle\"]],[23,4,[\"leftContentDescription\"]]]]],[23,2,[]],[28,\"hash\",null,[[\"title\",\"description\"],[[23,4,[\"rightContentTitle\"]],[23,4,[\"rightContentDescription\"]]]]]]]],[28,\"hash\",null,[[\"mobile\",\"desktop\"],[[28,\"hash\",null,[[\"text\",\"href\"],[[23,3,[\"mobileText\"]],[23,3,[\"mobileLink\"]]]]],[28,\"hash\",null,[[\"text\",\"href\"],[[23,3,[\"desktopText\"]],[23,3,[\"desktopLink\"]]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/centered-content/template.hbs"
    }
  });

  _exports.default = _default;
});