define("cms-frontend/components/layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nv093mE7",
    "block": "{\"symbols\":[\"modal\",\"module\",\"@sectionContainerClass\",\"@modules\",\"@modals\"],\"statements\":[[7,\"div\",true],[10,\"role\",\"presentation\"],[11,\"class\",[23,3,[]]],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[\"componentType\"]],\"menu\"],null]],null,{\"statements\":[[0,\"      \"],[5,\"cms-header\",[],[[\"@navigation\"],[[23,2,[\"menuItem\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[\"extraStyling\"]]],null,{\"statements\":[[0,\"        \"],[5,\"wrapper-components\",[[12,\"class\",[23,2,[\"extraStyling\",\"fields\",\"class\"]]],[12,\"style\",[28,\"meta-style\",[[23,2,[\"cssMeta\"]]],null]]],[[],[]],{\"statements\":[[0,\"\\n          \"],[1,[28,\"component\",[[28,\"concat\",[\"wrapper-components/\",[28,\"kebab-case\",[[23,2,[\"componentType\"]]],null]],null]],[[\"module\"],[[23,2,[]]]]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[5,\"wrapper-components\",[[12,\"style\",[28,\"meta-style\",[[23,2,[\"cssMeta\"]]],null]]],[[],[]],{\"statements\":[[0,\"\\n          \"],[1,[28,\"component\",[[28,\"concat\",[\"wrapper-components/\",[28,\"kebab-case\",[[23,2,[\"componentType\"]]],null]],null]],[[\"module\"],[[23,2,[]]]]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[2]},null],[9],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"concat\",[\"modals/\",[23,1,[\"componentName\"]]],null]],[[\"module\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[5,\"cms-footer\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/layout/template.hbs"
    }
  });

  _exports.default = _default;
});