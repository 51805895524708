define("cms-frontend/components/wrapper-components/hero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KNiKqg6Y",
    "block": "{\"symbols\":[\"heroBtn\",\"@module\"],\"statements\":[[5,\"hero\",[],[[\"@mobileImgSrc\",\"@imgSrc\",\"@title\",\"@description\"],[[28,\"transform-contentful-media\",[[23,2,[\"mobileImgSrc\"]]],null],[28,\"transform-contentful-media\",[[23,2,[\"imgSrc\"]]],null],[23,2,[\"title\"]],[23,2,[\"description\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"let\",[[28,\"transform-contentful\",[[23,2,[\"heroButton\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"button\",[],[[\"@isPrimary\",\"@url\"],[[28,\"not\",[[23,1,[\"isSecondary\"]]],null],[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,1,[\"mobileLink\"]],[23,1,[\"desktopLink\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"if\",[[28,\"is-mobile\",[\"any\"],null],[23,1,[\"mobileText\"]],[23,1,[\"desktopText\"]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/hero/template.hbs"
    }
  });

  _exports.default = _default;
});