define("cms-frontend/seo-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o1Ids5xb",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout\",[],[[\"@modules\",\"@sectionContainerClass\",\"@modals\"],[[23,0,[\"model\",\"modules\"]],[28,\"local-class\",[\"section-wrapper-container\"],[[\"from\"],[\"cms-frontend/seo-page/styles\"]]],[23,0,[\"model\",\"modal\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cms-frontend/seo-page/template.hbs"
    }
  });

  _exports.default = _default;
});