define("cms-frontend/helpers/transform-contentful-media", ["exports", "cms-frontend/utils/deserialize-contentful-model"], function (_exports, _deserializeContentfulModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * NOTE:
   *  Return new object , Do not modify existing object.
   *  which may cause app to break.
   *  TODO: @amerr refactor with clean solution (JCLARK-52946)
   * */
  function transformContentfulMedia(params, hash) {
    var _params = _slicedToArray(params, 1),
        model = _params[0];

    if (hash.list) {
      return model.map(function (i) {
        var _deserializeContentfu = (0, _deserializeContentfulModel.deserializeContentfulImageModel)(i[hash.prop]),
            url = _deserializeContentfu.url;

        return _objectSpread({}, i, _defineProperty({}, hash.prop, url));
      });
    }

    if (hash.prop) {
      var _deserializeContentfu2 = (0, _deserializeContentfulModel.deserializeContentfulImageModel)(model[hash.prop]),
          _url = _deserializeContentfu2.url;

      return _objectSpread({}, model, _defineProperty({}, hash.prop, _url));
    }

    var _deserializeContentfu3 = (0, _deserializeContentfulModel.deserializeContentfulImageModel)(model),
        url = _deserializeContentfu3.url,
        altText = _deserializeContentfu3.altText;

    return hash.altText ? altText : url;
  }

  var _default = Ember.Helper.helper(transformContentfulMedia);

  _exports.default = _default;
});