
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('cms-frontend/app')['default'].create({"name":"cms-frontend","version":"0.0.0+939462a3"});
  }
}

define('~fastboot/app-factory', ['cms-frontend/app', 'cms-frontend/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

