define("cms-frontend/utils/deserialize-contentful-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deserializeContentfulModel = deserializeContentfulModel;
  _exports.deserializeContentfulImageModel = deserializeContentfulImageModel;

  function deserializeContentfulModel(model) {
    (false && !(Ember.isPresent(model)) && Ember.assert("deserializeContentfulModel: Empty model is passed", Ember.isPresent(model)));
    (false && !(Ember.isPresent(model.sys) && Ember.isPresent(model.fields)) && Ember.assert("deserializeContentfulModel: Model is not valid Contentful CMS model ", Ember.isPresent(model.sys) && Ember.isPresent(model.fields)));
    return model.fields;
  }

  function deserializeContentfulImageModel(model) {
    var data = deserializeContentfulModel(model);
    return {
      url: data.file.url,
      altText: data.description
    };
  }
});